var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InsertRowPosition = exports.FilterOperatorName = exports.FilteringMode = exports.SortingMode = exports.SortDirection = exports.KeyboardEnum = exports.ActionType = exports.EditingMode = exports.DataType = exports.PagingPosition = void 0;
var PagingPosition;
(function (PagingPosition) {
  PagingPosition["Bottom"] = "bottom";
  PagingPosition["Top"] = "top";
  PagingPosition["TopAndBottom"] = "topAndBottom";
})(PagingPosition = exports.PagingPosition || (exports.PagingPosition = {}));
var DataType;
(function (DataType) {
  // eslint-disable-next-line
  DataType["Boolean"] = "boolean";
  DataType["Date"] = "date";
  // eslint-disable-next-line
  DataType["Number"] = "number";
  DataType["Object"] = "object";
  // eslint-disable-next-line
  DataType["String"] = "string";
})(DataType = exports.DataType || (exports.DataType = {}));
var EditingMode;
(function (EditingMode) {
  EditingMode["None"] = "none";
  EditingMode["Cell"] = "cell";
})(EditingMode = exports.EditingMode || (exports.EditingMode = {}));
var ActionType;
(function (ActionType) {
  ActionType["ClearFocused"] = "ClearFocused";
  ActionType["ClearSingleAction"] = "ClearSingleAction";
  ActionType["CloseEditor"] = "CloseEditor";
  ActionType["CloseRowEditors"] = "CloseRowEditors";
  ActionType["ComponentDidMount"] = "ComponentDidMount";
  ActionType["DeleteRow"] = "DeleteRow";
  ActionType["DeselectAllFilteredRows"] = "DeselectAllFilteredRows";
  ActionType["DeselectAllRows"] = "DeselectAllRows";
  ActionType["DeselectAllVisibleRows"] = "DeselectAllVisibleRows";
  ActionType["DeselectRow"] = "DeselectRow";
  ActionType["DeselectRows"] = "DeselectRows";
  ActionType["GroupColumn"] = "GroupColumn";
  ActionType["HideColumn"] = "HideColumn";
  ActionType["HideDetailsRow"] = "HideDetailsRow";
  ActionType["HideLoading"] = "HideLoading";
  ActionType["HideNewRow"] = "HideNewRow";
  ActionType["InsertRow"] = "InsertRow";
  ActionType["LoadData"] = "LoadData";
  ActionType["InsertColumn"] = "InsertColumn";
  ActionType["MoveColumnBefore"] = "MoveColumnBefore";
  ActionType["MoveColumnToIndex"] = "MoveColumnToIndex";
  ActionType["MoveFocusedDown"] = "MoveFocusedDown";
  ActionType["MoveFocusedLeft"] = "MoveFocusedLeft";
  ActionType["MoveFocusedRight"] = "MoveFocusedRight";
  ActionType["MoveFocusedUp"] = "MoveFocusedUp";
  ActionType["OpenAllEditors"] = "OpenAllEditors";
  ActionType["OpenEditor"] = "OpenEditor";
  ActionType["OpenRowEditors"] = "OpenRowEditors";
  ActionType["ReorderColumns"] = "ReorderColumns";
  ActionType["ReorderRows"] = "ReorderRows";
  ActionType["ResizeColumn"] = "ResizeColumn";
  ActionType["SaveAllEditors"] = "SaveAllEditors";
  ActionType["SaveNewRow"] = "SaveNewRow";
  ActionType["SaveRowEditors"] = "SaveRowEditors";
  ActionType["ScrollTable"] = "ScrollTable";
  ActionType["Search"] = "Search";
  ActionType["SelectAllFilteredRows"] = "SelectAllFilteredRows";
  ActionType["SelectAllRows"] = "SelectAllRows";
  ActionType["SelectAllVisibleRows"] = "SelectAllVisibleRows";
  ActionType["SelectRow"] = "SelectRow";
  ActionType["SelectRows"] = "SelectRows";
  ActionType["SelectRowsRange"] = "SelectRowsRange";
  ActionType["SelectSingleRow"] = "SelectSingleRow";
  ActionType["SetFocused"] = "SetFocused";
  ActionType["SetSingleAction"] = "SetSingleAction";
  ActionType["ShowColumn"] = "ShowColumn";
  ActionType["ShowDetailsRow"] = "ShowDetailsRow";
  ActionType["ShowLoading"] = "ShowLoading";
  ActionType["ShowNewRow"] = "ShowNewRow";
  ActionType["UngroupColumn"] = "UngroupColumn";
  ActionType["UpdateCellValue"] = "UpdateCellValue";
  ActionType["UpdateData"] = "UpdateData";
  ActionType["UpdateEditorValue"] = "UpdateEditorValue";
  ActionType["UpdateFilterRowOperator"] = "UpdateFilterRowOperator";
  ActionType["UpdateFilterRowValue"] = "UpdateFilterRowValue";
  ActionType["UpdateGroupsExpanded"] = "UpdateGroupsExpanded";
  ActionType["UpdateHeaderFilterValues"] = "UpdateHeaderFilterValues";
  ActionType["UpdateHeaderFilterPopupState"] = "UpdateHeaderFilterPopupState";
  ActionType["UpdatePopupPosition"] = "UpdatePopupPosition";
  ActionType["UpdatePageIndex"] = "UpdatePageIndex";
  ActionType["UpdatePageSize"] = "UpdatePageSize";
  ActionType["UpdatePagesCount"] = "UpdatePagesCount";
  ActionType["UpdateRow"] = "UpdateRow";
  ActionType["UpdateSortDirection"] = "UpdateSortDirection";
  ActionType["UpdateTreeGroupsExpanded"] = "UpdateTreeGroupsExpanded";
  ActionType["UpdateVirtualScrolling"] = "UpdateVirtualScrolling";
  ActionType["Validate"] = "Validate";
})(ActionType = exports.ActionType || (exports.ActionType = {}));
var KeyboardEnum;
(function (KeyboardEnum) {
  KeyboardEnum[KeyboardEnum["Esc"] = 27] = "Esc";
  KeyboardEnum[KeyboardEnum["Enter"] = 13] = "Enter";
})(KeyboardEnum = exports.KeyboardEnum || (exports.KeyboardEnum = {}));
var SortDirection;
(function (SortDirection) {
  SortDirection["Ascend"] = "ascend";
  SortDirection["Descend"] = "descend";
})(SortDirection = exports.SortDirection || (exports.SortDirection = {}));
var SortingMode;
(function (SortingMode) {
  SortingMode["None"] = "none";
  SortingMode["Single"] = "single";
  SortingMode["SingleTripleState"] = "singleTripleState";
  SortingMode["SingleRemote"] = "singleRemote";
  SortingMode["SingleTripleStateRemote"] = "singleTripleStateRemote";
  SortingMode["MultipleRemote"] = "multipleRemote";
  SortingMode["MultipleTripleStateRemote"] = "multipleTripleStateRemote";
})(SortingMode = exports.SortingMode || (exports.SortingMode = {}));
var FilteringMode;
(function (FilteringMode) {
  FilteringMode["None"] = "none";
  FilteringMode["FilterRow"] = "filterRow";
  FilteringMode["HeaderFilter"] = "headerFilter";
  FilteringMode["FilterRowAndHeaderFilter"] = "filterRowAndHeaderFilter";
})(FilteringMode = exports.FilteringMode || (exports.FilteringMode = {}));
var FilterOperatorName;
(function (FilterOperatorName) {
  FilterOperatorName["Equal"] = "=";
  FilterOperatorName["MoreThan"] = ">";
  FilterOperatorName["LessThan"] = "<";
  FilterOperatorName["MoreThanOrEqual"] = ">=";
  FilterOperatorName["LessThanOrEqual"] = "<=";
  FilterOperatorName["Contains"] = "contains";
  FilterOperatorName["IsEmpty"] = "IsEmpty";
  FilterOperatorName["IsNotEmpty"] = "IsNotEmpty";
})(FilterOperatorName = exports.FilterOperatorName || (exports.FilterOperatorName = {}));
var InsertRowPosition;
(function (InsertRowPosition) {
  InsertRowPosition["before"] = "before";
  InsertRowPosition["after"] = "after";
})(InsertRowPosition = exports.InsertRowPosition || (exports.InsertRowPosition = {}));
export default exports;
export const __esModule = exports.__esModule;
const _InsertRowPosition = exports.InsertRowPosition,
  _FilterOperatorName = exports.FilterOperatorName,
  _FilteringMode = exports.FilteringMode,
  _SortingMode = exports.SortingMode,
  _SortDirection = exports.SortDirection,
  _KeyboardEnum = exports.KeyboardEnum,
  _ActionType = exports.ActionType,
  _EditingMode = exports.EditingMode,
  _DataType = exports.DataType,
  _PagingPosition = exports.PagingPosition;
export { _InsertRowPosition as InsertRowPosition, _FilterOperatorName as FilterOperatorName, _FilteringMode as FilteringMode, _SortingMode as SortingMode, _SortDirection as SortDirection, _KeyboardEnum as KeyboardEnum, _ActionType as ActionType, _EditingMode as EditingMode, _DataType as DataType, _PagingPosition as PagingPosition };